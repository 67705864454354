exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artist-tsx": () => import("./../../../src/pages/artist.tsx" /* webpackChunkName: "component---src-pages-artist-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-templates-collection-info-tsx": () => import("./../../../src/templates/collection-info.tsx" /* webpackChunkName: "component---src-templates-collection-info-tsx" */),
  "component---src-templates-collection-list-tsx": () => import("./../../../src/templates/collection-list.tsx" /* webpackChunkName: "component---src-templates-collection-list-tsx" */),
  "component---src-templates-collection-property-tsx": () => import("./../../../src/templates/collection-property.tsx" /* webpackChunkName: "component---src-templates-collection-property-tsx" */)
}

